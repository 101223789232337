<template>
  <div class="animated fadeIn">
    <b-form @submit.prevent="onUpdate" novalidate>
      <!-- Customer Information -->
      <b-card-header>
        Customer Information
        <div class="card-header-actions">
          <a class="card-header-action" href="verifikasi" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card>
        <b-row class="mb-4">
          <b-col class="mb-4" sm="6">
            <h6>Name</h6>
            <b-form-input id="name"
              type="text"
              size="md"
              v-model="form.name"
              :disabled="form.ktpStatus === 2 || form.ktpStatus === 3 ? false : true"
              aria-describedby="reasonFeedback"
              placeholder="Masukan Nama"
              autocomplete='given-name'
              autofocus
            ></b-form-input>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>Virtual account user</h6>
            <b-input-group>
              <b-form-input
                id="vaUser"
                type="text"
                :value="parseValue(data.name)"
                disabled
              />
            </b-input-group>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>Email</h6>
            <b-input-group>
              <b-form-input
                id="email"
                type="text"
                :value="parseValue(data.email)"
                disabled
              />
            </b-input-group>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>Tanggal Lahir</h6>
            <b-form-group class="mb-0" id="date" label-for="date">
              <date-picker
                v-model="form.birthday"
                format="DD-MM-YYYY"
                type="date"
                :disabled="form.ktpStatus === 2 || form.ktpStatus === 3 ? false : true"
                lang="en"
                value-type="format"
                placeholder="DD-MM-YYYY"
                style="width: 100%;"
              ></date-picker>
            </b-form-group>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>No Handphone</h6>
            <b-input-group>
              <b-form-input
                id="phone"
                type="text"
                :value="parseValue(data.phone)"
                disabled
              />
            </b-input-group>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>Jenis Kelamin:</h6>
            <b-form-group class="mb-0" id="gender" label-for="idcard">
              <b-form-select
                v-model="form.gender"
                :disabled="form.ktpStatus === 2 || form.ktpStatus === 3 ? false : true"
                :options="[
                  { text: 'Select Gender', value: '' },
                  { text: 'Perempuan', value: 'Female' },
                  { text: 'Laki-Laki', value: 'Male' }
                ]"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>Nomor KTP</h6>
            <b-form-group id="userNameInputGroup3" label-for="idcard">
              <b-form-input id="idcard"
                type="number"
                size="md"
                :disabled="form.ktpStatus === 2 || form.ktpStatus === 3 ? false : true"
                v-model="form.idNumber"
                @change="isUnique"
                aria-describedby="reasonFeedback"
                placeholder="Masukan Nomor KTP"
                autocomplete='given-name'
              >
              </b-form-input>
              <span class="warning" v-if="warning_message !== ''">
                {{warning_message}}
              </span>
            </b-form-group>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>Partner/Organization</h6>
            <b-input-group>
              <b-form-input
                id="partner"
                type="text"
                :value="parseValue(this.data.partner ? this.data.partner : this.data.organization ? this.data.organization : null)"
                disabled
              />
            </b-input-group>
          </b-col>
        </b-row>
      </b-card>
      <!-- Customer Information -->
      <b-card-header>
        Customer Information
      </b-card-header>
      <b-card>
        <b-row class="mb-4">
          <b-col sm="6">
            <!-- KTP Status -->
            <div class="mb-4">
              <b-form-group label="KTP Status">
                <b-form-radio-group id="ktp--radio" v-model="form.ktpStatus" @change="radioChange('status-ktp')" name="ktp--radio">
                  <b-form-radio :value="0" >Not Verified</b-form-radio>
                  <b-form-radio :value="1" >Verified</b-form-radio>
                  <b-form-radio :value="2" >Rejected</b-form-radio>
                  <b-form-radio :value="3" >On Process</b-form-radio>
                  <b-form-radio :value="4" >Submitted</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group id="ktpReason" label-for="reason-ktp" v-show="form.ktpStatus === 2">
                <b-form-textarea
                  id="reason-ktp"
                  placeholder="Reason KTP"
                  rows="3"
                  v-model="form.reasonKtp"
                  no-resize
                  required
                ></b-form-textarea>
              </b-form-group>
            </div>
            <!-- Forgery Status -->
            <div class="mb-4">
              <h6>Forgery Status</h6>
              <p class="text-red" v-if="data.forgery_status && data.forgery_status.includes('Rejected')"><strong>{{ this.data.forgery_status }}</strong></p>
              <p class="text-success" v-else-if="data.forgery_status && data.forgery_status.includes('Verified')"><strong>{{ this.data.forgery_status }}</strong></p>
              <p v-else><strong>{{ this.data.forgery_status }}</strong></p>
            </div>
            <!-- Upload KTP -->
            <div class="mb-4">
              <h6>Upload Foto KTP</h6>
              <div class="upload-images-kyc">
                <b-form-file
                  class="mb-4"
                  v-model="form.fileKtp"
                  ref="file-ktp"
                  accept=".jpeg, .jpg, .png"
                  @change="checkExtension($event, 'file-ktp')"
                  plain>
                </b-form-file>
                <a v-if="form.imageKtp" :href="form.imageKtp" target="_blank" rel="noopener noreferrer">
                  <img :src="form.imageKtp" alt='Image KTP'/>
                </a>
              </div>
            </div>
            <!-- Verifikasi -->
            <div class="mb-4">
              <h6>Diverifikasi Oleh</h6>
              <p><strong>{{ this.data.action_by }}</strong></p>
            </div>
          </b-col>
          <b-col sm="6">
            <!-- Selfie Status -->
            <div class="mb-4">
              <b-form-group label="Selfie Status">
                <b-form-radio-group id="selfie--radio" v-model="form.selfieStatus" @change="radioChange('status-selfie')" name="selfie--radio">
                  <b-form-radio :value="0" >Not Verified</b-form-radio>
                  <b-form-radio :value="1" >Verified</b-form-radio>
                  <b-form-radio :value="2" >Rejected</b-form-radio>
                  <b-form-radio :value="3" >On Process</b-form-radio>
                  <b-form-radio :value="4" >Submitted</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group id="ktpReason" label-for="reason-selfie" v-show="form.selfieStatus === 2">
                <b-form-textarea
                  id="reason-selfie"
                  placeholder="Reason Selfie"
                  rows="3"
                  v-model="form.reasonSelfie"
                  no-resize
                  required
                ></b-form-textarea>
              </b-form-group>
            </div>
            <!-- Selfie Presentase -->
            <div class="mb-4">
              <div class="d-flex align-items-center justify-content-between">
                <h6>Persentase Foto Selfie</h6>
                <h6>{{ `${this.data.liveness_detection}%` }}</h6>
              </div>
              <b-progress :value="this.data.liveness_detection" :max="100" ></b-progress>
            </div>
            <!-- Selfie & KTP Presentase -->
            <div class="mb-4">
              <div class="d-flex align-items-center justify-content-between">
                <h6>Persentase Kecocokan Foto KTP dan Foto Selfie</h6>
                <h6>{{ `${this.data.compare_percentage}%` }}</h6>
              </div>
              <b-progress :value="this.data.compare_percentage" :max="100" ></b-progress>
            </div>
            <!-- Upload Selfie -->
            <div class="mb-4">
              <h6>Upload Foto Selfie</h6>
              <div class="upload-images-kyc">
                <b-form-file
                  class="mb-4"
                  v-model="form.fileSelfie"
                  ref="file-selfie"
                  accept=".jpeg, .jpg, .png"
                  @change="checkExtension($event, 'file-selfie')"
                  plain>
                </b-form-file>
                <a v-if="form.imageSelfie" :href="form.imageSelfie" target="_blank" rel="noopener noreferrer">
                  <img :src="form.imageSelfie" alt='Image Selfie'/>
                </a>
              </div>
            </div>
            <!-- Verifikasi -->
            <div class="mb-4">
              <h6>Diverifikasi Oleh</h6>
              <p><strong>{{ this.data.action_by }}</strong></p>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <!-- KYC Attempt -->
      <b-card-header>
        KYC Attempt
      </b-card-header>
      <b-card>
        <b-row class="mb-4">
          <b-col sm="3">
            <h5>ID Forgery</h5>
            <p><strong>{{this.data.attempt.id_forgery}} Attempt</strong></p>
          </b-col>
          <b-col sm="3">
            <h5>Photo Selfie</h5>
            <p><strong>{{this.data.attempt.selfie}} Attempt</strong></p>
          </b-col>
          <b-col sm="3">
            <h5>Face Compare</h5>
            <p><strong>{{this.data.attempt.face_compare}} Attempt</strong></p>
          </b-col>
          <b-col sm="3">
            <h5>Dukcapil Verification</h5>
            <p><strong>{{this.data.attempt.smma}} Attempt</strong></p>
          </b-col>
        </b-row>
      </b-card>
      <!-- Address Information -->
      <b-card-header>
        Alamat sesuai KTP
      </b-card-header>
      <b-card>
        <b-row class="mb-4">
          <b-col class="mb-4" sm="6">
            <h6>Alamat</h6>
            <b-form-input id="address"
              type="text"
              size="md"
              v-model="form.address"
              :disabled="form.ktpStatus === 2 || form.ktpStatus === 3 ? false : true"
              aria-describedby="reasonFeedback"
              placeholder="Masukan Alamat"
              autocomplete='given-name'
              autofocus
            ></b-form-input>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>District</h6>
            <v-select
              id="district"
              v-model="form.district"
              placeholder="Select District"
              aria-describedby="districtFeedback"
              :options="districtOption"
              :disabled="form.ktpStatus === 2 || form.ktpStatus === 3 ? false : true"
              :on-change="checkDistrict"
              style="padding-top: 0;"
            ></v-select>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>Province</h6>
            <v-select
              id="province"
              v-model="form.province"
              placeholder="Select Province"
              aria-describedby="provinceFeedback"
              :options="provinceOption"
              :disabled="form.ktpStatus === 2 || form.ktpStatus === 3 ? false : true"
              :on-change="checkProvince"
              style="padding-top: 0;"
            ></v-select>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>Village</h6>
            <v-select
              id="village"
              v-model="form.village"
              placeholder="Select village"
              aria-describedby="villageFeedback"
              :options="villageOption"
              :disabled="form.ktpStatus === 2 || form.ktpStatus === 3 ? false : true"
              :on-change="fillPostalCode"
              style="padding-top: 0;"
            ></v-select>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>City</h6>
            <v-select
              id="city"
              v-model="form.city"
              placeholder="Select City"
              aria-describedby="cityFeedback"
              :options="cityOption"
              :disabled="form.ktpStatus === 2 || form.ktpStatus === 3 ? false : true"
              :on-change="checkCity"
              style="padding-top: 0;"
            ></v-select>
          </b-col>
          <b-col class="mb-4" sm="6">
            <h6>Postal Code</h6>
            <b-form-input id="postal_code"
              type="number"
              size="md"
              v-model="form.postalCode"
              :disabled="form.ktpStatus === 2 || form.ktpStatus === 3 ? false : true"
              aria-describedby="postalCodeFeedback"
              placeholder="Masukan PostalCode"
              autocomplete='given-name'
              autofocus
            ></b-form-input>
          </b-col>
        </b-row>
      </b-card>
      <b-card-body class="mt-0 mb-5 pt-0 pl-0">
        <b-button type="submit" variant="primary">
          Submit
        </b-button>
      </b-card-body>
    </b-form>
  </div>
</template>

<script>
import axios from  'axios'
import moment from 'moment'

export default {
  name: 'detail-verifikasi',
  components: {
  },
  data() {
    return {
      data: {},
      form: {
        name: '',
        birthday: '',
        gender: '',
        idNumber: '',
        ktpStatus: '',
        selfieStatus: '',
        reasonKtp: '',
        reasonSelfie: '',
        fileKtp: null,
        fileSelfie: null,
        imageKtp: null,
        imageSelfie: null,
        address: '',
        district: '',
        districtId: '',
        province: '',
        provinceId: '',
        village: '',
        villageId: '',
        city: '',
        cityId: '',
        postalCode: ''
      },
      isLoading: false,
      provinceData: [],
      provinceOption: [],
      cityData: [],
      cityOption: [],
      districtData: [],
      districtOption: [],
      villageData: [],
      villageOption: [],
      warning_message: '',
      errors: {
        code: '',
        message: '',
        status: ''
      },
    }
  },
  created () {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
    this.onFetchDetail()
  },
  watch: {
    provinceData() {
      if (this.form.province && !this.form.provinceId && this.provinceData.length > 0) this.form.provinceId = this.getIdValue(this.form.province, this.provinceData);
    },
    cityData() {
      if (this.form.city && !this.form.cityId && this.cityData.length > 0) this.form.cityId = this.getIdValue(this.form.city, this.cityData);
    },
    districtData() {
      if (this.form.district && !this.form.districtId && this.districtData.length > 0) this.form.districtId = this.getIdValue(this.form.district, this.districtData);
    },
    villageData() {
      if (this.form.village && !this.form.villageId && this.villageData.length > 0) this.form.villageId = this.getIdValue(this.form.village, this.villageData);
    },
  },
  methods: {
    getIdValue(val, data) {
      if (val && data.length > 0) {
        const find = data.filter((v) => {
          return v.text === val;
        })

        if (find.length > 0) return find[0].value;
      } else {
        return null;
      }
    },
    radioChange(val) {
      if (val === 'status-ktp' && this.form.ktpStatus !== 2) {
        this.form.reasonKtp = '';
      } else if (val === 'status-selfie' && this.form.selfieStatus !== 2) {
        this.form.reasonSelfie = '';
      }
    },
    isUnique (value) {
      if (value === '') return true

      return new Promise ((resolve, reject) => {
        this.$http.post(`/verify-check/${value}`)
          .then((res) => {
            if (res.data.data.exists === true) {
              this.warning_message = 'Nomor KTP sudah terpakai. Silakan cek kembali. Kamu tetap bisa submit verifikasi.'
            } else {
              this.warning_message = ''
            }
            resolve(res)
          })
          .catch((error) => {
            if (error.response) {
              reject(error)
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
            }
          })
      })
    },
    parseValue(event) {
      return event ? event : '-';
    },
    checkExtension(value, type) {
      const validExts = new Array('.jpeg', '.jpg', '.png');
      const maxFileSize = 1024 * 1024; // 1MB
      const files = value.target.files[0];
      const fileExt = files.name.substring(files.name.lastIndexOf('.'));
      let errorMessage = '';

      if (!validExts.includes(fileExt)) {
        errorMessage = `Invalid file selected, valid files are of ${validExts.toString()} types.`;
      } else if (files.size > maxFileSize) {
        errorMessage = 'File size exceeds the 1MB limit.';
      } else if (!validExts.includes(fileExt) && files.size > maxFileSize) {
        errorMessage = `Invalid file selected, valid files are of ${validExts.toString()} types, and file size exceeds the 1MB limit.`;
      }

      if (!validExts.includes(fileExt) || files.size > maxFileSize) {
        this.$swal.fire(
          'Failed!',
          errorMessage,
          'error'
        ).then(() => {
          return false;
        })

        return false;
      } else {
        if (type === 'file-ktp') {
          this.form.fileKtp = files;
          this.form.imageKtp = URL.createObjectURL(files);
        } else {
          this.form.fileSelfie = files;
          this.form.imageSelfie = URL.createObjectURL(files);
        }

        return true;
      }
    },
    onUpdate() {
      const formData = new FormData();
      formData.append('address', this.form.address);
      formData.append('birthday', this.form.birthday);
      formData.append('city_id', this.form.cityId);
      formData.append('district_id', this.form.districtId);
      formData.append('gender', this.form.gender);
      formData.append('id_number', this.form.idNumber);
      formData.append('ktp_reason', this.form.reasonKtp);
      formData.append('ktp_status', this.form.ktpStatus);
      formData.append('name', this.form.name);
      formData.append('postal_code', this.form.postalCode);
      formData.append('province_id', this.form.provinceId);
      formData.append('selfie_reason', this.form.reasonSelfie);
      formData.append('selfie_status', this.form.selfieStatus);
      formData.append('phone', this.data.phone);
      formData.append('village_id', this.form.villageId);
      formData.append('idcard_image', typeof this.form.fileKtp === 'object' ? this.form.fileKtp : '');
      formData.append('selfie_image', typeof this.form.fileSelfie === 'object' ? this.form.fileSelfie : '');
      
      this.isLoading = true;
      this.$http.post(`verify-update/${this.data.id}`, formData).then(() => {
        this.isLoading = false
        this.$swal.fire(
          'Success!',
          'Ktp was successfully Updated.',
          'success'
        ).then(() =>{
          this.$router.push({ name: 'Verifikasi KYC' });
        })
      }).catch((error) => {
        if (error.response) {
          this.isLoading = false
          this.errors.code = error.response.status;
          this.errors.message = error.response.data.meta.message;
          this.errors.status = error.response.data.meta.code;
          if (this.errors.status === 404) {
            this.$swal.fire(
              'Failed!',
              'Ktp failed to updated.',
              'error'
            ).then(() =>{
              location.reload()
            })
          }

          if (this.errors.status === 400) {
            this.$swal.fire(
              'Failed!',
              this.errors.message,
              'error'
            )
          }
        }
      })
    },
    async onFetchDetail() {
      this.isLoading = true;
      await this.$http.put(`verify/` + atob(this.$route.params.id))
        .then((result) => {
          this.isLoading = false;
          this.data = result.data.verify;
          this.form = {
            name: result.data.verify.name,
            birthday: result.data.verify.birthday ? moment(result.data.verify.birthday).format('DD-MM-YYYY') : '',
            gender: result.data.verify.gender,
            idNumber: result.data.verify.id_number,
            ktpStatus: result.data.verify.ktp_status,
            selfieStatus: result.data.verify.selfie_status,
            reasonKtp: result.data.verify.ktp_reason || '',
            reasonSelfie: result.data.verify.selfie_reason || '',
            imageKtp: result.data.verify.id_card_scan,
            imageSelfie: result.data.verify.selfie_scan,
            address: result.data.verify.address,
            district: result.data.verify.district_id,
            province: result.data.verify.province_id,
            village: result.data.verify.village_id,
            city: result.data.verify.city_id,
            postalCode: result.data.verify.postal_code
          }
        }).catch((error) => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.message = error.response.data.meta.message;
            this.errors.status = error.response.data.meta.code;
          }
        })

        // Get Province
        await this.getProvince();

        // Get City
        if (this.form.province) await this.getCity(this.form.province);

        // Get District
        if (this.form.city) await this.getDistrict(this.form.city);

        // Get Village
        if (this.form.city && this.form.district) await this.getVillage(this.form.city, this.form.district);
    },
    async checkProvince(val){
      if (!val || val !== this.form.province) {
        const findProvince = this.getIdValue(val, this.provinceData);

        this.form.province = val;
        this.form.provinceId = findProvince || '';
        this.form.city = null;
        this.form.cityId = '';
        this.form.district = null;
        this.form.districtId = '';
        this.form.village = null;
        this.form.villageId = '';
        this.form.postalCode = '';
        this.cityOption = [];
        this.districtOption = [];
        this.villageOption = [];

        await this.getCity(this.form.province);
      }
    },
    async checkCity(val){
      if (!val || val !== this.form.city) {
        const findCity = this.getIdValue(val, this.cityData);

        this.form.city = val;
        this.form.cityId = findCity || '';
        this.form.district = null;
        this.form.districtId = '';
        this.form.village = null;
        this.form.villageId = '';
        this.form.postalCode = '';
        this.districtOption = [];
        this.villageOption = [];
  
        if (this.form.city) await this.getDistrict(this.form.city);
      }
    },
    async checkDistrict(val){
      if (!val || val !== this.form.district) {
        const findDistrict = this.getIdValue(val, this.districtData);

        this.form.district = val;
        this.form.districtId = findDistrict || '';
        this.form.village = null;
        this.form.villageId = '';
        this.form.postalCode = '';
        this.villageOption = [];
  
        if (this.form.city && this.form.district) this.getVillage(this.form.city, this.form.district);
      }
    },
    async fillPostalCode(val) {
      if (!val || val !== this.form.village) {
        const findVillage = this.getIdValue(val, this.villageData);

        this.isLoading = true;
        this.form.village = val;
        this.form.villageId = findVillage || '';
  
        if (this.form.city && this.form.district && this.form.village) {
          const city = this.cityData.filter((v) => {
            return v.text === this.form.city
          })
  
          const district = this.districtData.filter((v) => {
            return v.text === this.form.district
          })
  
          await axios.get(process.env.VUE_APP_SECRET + `admin/v2/command/postal-code-command?city=${city[0].value}&district=${district[0].value}&village=${this.form.village}`).then((res) => {
            this.isLoading = false;
            this.form.postalCode = res.data.data;
          })
        } else {
          this.form.postalCode = '';
        }
  
        this.isLoading = false;
      }
    },
    async getProvince() {
      this.isLoading = true;
      await axios.get(process.env.VUE_APP_SECRET + 'admin/v2/command/province-command').then((res) => {
        this.isLoading = false;
        this.provinceData = res.data.data;
        this.provinceOption = res.data.data.map(itm => { return itm.text });
      })
    },
    async getCity(val){
      this.isLoading = true

      if (val) {
        const findProvince = this.provinceData.filter((v) => {
          return v.text === val
        })

        await axios.get(process.env.VUE_APP_SECRET + `admin/v2/command/city-command?province=${findProvince[0].value}`).then((res) => {
          this.isLoading = false;
          this.cityData = res.data.data;
          this.cityOption = res.data.data.map(itm => { return itm.text });
        })
      } else {
        await axios.get(process.env.VUE_APP_SECRET + `admin/v2/command/city-command`).then((res) => {
          this.isLoading = false;
          this.cityData = res.data.data;
          this.cityOption = res.data.data.map(itm => { return itm.text });
        })
      }
    },
    async getDistrict(val){
      this.isLoading = true;

      if (val) {
        const findCity = this.cityData.filter((v) => {
          return v.text === val
        })

        await axios.get(process.env.VUE_APP_SECRET + `admin/v2/command/district-command?city=${findCity[0].value}`).then((res) => {
          this.isLoading = false;
          this.districtData = res.data.data;
          this.districtOption = res.data.data.map(itm => { return itm.text })
        })
      } else {
        await axios.get(process.env.VUE_APP_SECRET + `admin/v2/command/district-command`).then((res) => {
          this.isLoading = false;
          this.districtData = res.data.data;
          this.districtOption = res.data.data.map(itm => { return itm.text })
        })
      }
    },
    async getVillage(city, district){
      this.isLoading = true;

      if (city && district) {
        const findDistrict = this.districtData.filter((v) => {
          return v.text === district
        })

        const findCity = this.cityData.filter((v) => {
          return v.text === city
        })
        
        await axios.get(process.env.VUE_APP_SECRET + `admin/v2/command/village-command?city=${findCity[0].value}&district=${findDistrict[0].value}`).then((res) => {
          this.isLoading = false;
          this.villageData = res.data.data;
          this.villageOption = res.data.data.map(itm => { return itm.text })
        })
      }

      this.isLoading = false;
    }
  }
}
</script>

<style>
  .submt {
    width: 100%;
    height: auto;
    display: block;
  }
</style>
<style>
.form-control {
  height: 36.22px;
}

.upload-images-kyc img {
  width: fit-content;
  height: 160px;
}

.text-red {
  color: #CB211F;
}
.text-success {
  color: #35AA4B;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
